'use strict';

var test = {
  foo: {}
};
var $Object = Object;
module.exports = function hasProto() {
  return {
    __proto__: test
  }.foo === test.foo && !({
    __proto__: null
  } instanceof $Object);
};